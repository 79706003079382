import { InputNumber, InputText } from "../../../../../ui-components/forms"
import { useEffect, useState } from "react";
import CalcsModal, { CalcsData, CalcsType } from "./calcs-modal";

interface CalcsStepElement {
    title: string,
    data: CalcsData
    updateCalcs: (data: CalcsData) => void
}

const CalcCqsStepElement = ({ title, data, updateCalcs }: CalcsStepElement) => {
    const [currentData, setCurrentData] = useState<CalcsData>(data);
    const [showCalcs, setShowCalcs] = useState<boolean>(false);

    const [importLett, setImportLett] = useState<string>(data.importLett ?? "");
    const [rncImport, setRncImport] = useState<number>(data.rncImport ?? 0)
    const [rncImportLett, setRncImportLett] = useState<string>(data.rncImportLett ?? "");

    const [localData, setLocalData] = useState(currentData);

    return (
        <>
            <div className="row">
                <h4 style={{ textAlign: "center" }}>{title}</h4>
                <div className="d-flex col-12 justify-content-end">
                    <button
                        className={"btn btn-primary"}
                        onClick={() => setShowCalcs(!showCalcs)}
                    >
                        Genera calcoli
                    </button>
                </div>

                {currentData.haveCalcs ? <>
                    <div className="col-12 body my-3">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Ristorni</th>
                                    <th></th>
                                    <th>Già Stornati</th>
                                    <th></th>
                                    <th>Da Richiedere</th>
                                </tr>
                            </thead>
                            <tbody>
                                {localData && localData.expenses?.length > 0 ? localData.expenses.map((exps, index) => {
                                    return (
                                        <tr key={`${exps.order}_${index}`}>
                                            <td>{exps.label}</td>
                                            <td>&euro; {(exps.amount * localData.percSpes).toFixed(2)}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    value={exps.edit ?? 0}
                                                    onChange={(e) => {
                                                        const newEdit = parseFloat(e.target.value) || 0;
                                                        setLocalData(prev => {
                                                            const updatedExpenses = [...prev.expenses];
                                                            updatedExpenses[index] = {
                                                                ...updatedExpenses[index],
                                                                edit: newEdit
                                                            };
                                                            return { ...prev, expenses: updatedExpenses };
                                                        });
                                                    }}
                                                />
                                            </td>
                                            <td></td>
                                            <td>
                                                &euro; {((exps.amount * localData.percSpes) - (exps.edit ?? 0)).toFixed(2)}
                                            </td>
                                        </tr>
                                    );
                                }) : null}
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><strong>Totale</strong></td>
                                    <td><strong>&euro; {localData.expenses.reduce((acc, currentVal) => {
                                        return (acc + (currentVal.amount * localData.percSpes) - currentVal.edit)
                                    }, 0).toFixed(2)}</strong></td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><strong>Importo in lettere</strong></td>
                                    <td><input type="text" defaultValue={importLett} onChange={e => setImportLett(e.target.value)} /></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={5} className="text-muted small">Calcoli generati seguendo il rapporto fra il numero di {currentData.calcsType === CalcsType.INSTALLMENTS ? "rate pagate" : "interessi contrattualizzati e quelli pagati"} </td>
                                </tr>
                            </tfoot>
                        </table>

                        <hr />

                        <h5 className="fw-bold">Rate non contabilizzate</h5>
                        <div className="row">
                            <div className="col-md-3">
                                <InputNumber validation={null} price={true} step="0.01" type="number" label="Importo" name="rncImport" defaultValue={rncImport} onChange={setRncImport} />
                            </div>
                            <div className="col-md-3">
                                <InputText label="Importo in Lettere" name="rncImportLett" defaultValue={rncImportLett} onChange={setRncImportLett} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <button
                            className={"btn btn-primary"}
                            onClick={() => {
                                updateCalcs({
                                    ...currentData,
                                    expenses: localData.expenses,
                                    importLett,
                                    rncImport,
                                    rncImportLett
                                })
                            }}
                        >
                            Conferma
                        </button>
                    </div>
                </> : null}

                <hr className="mt-3" />
            </div>

            {showCalcs && <CalcsModal closeModal={(value) => setShowCalcs(value)} data={currentData} newData={(newData) => { setCurrentData(newData) }} />}
        </>
    )
}

export default CalcCqsStepElement