import { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { useCustomer } from '../../../contexts/customer-context';
import FormCustomer from '../../../ui-components/forms-components/customer/customer';
import CustomerMemos from '../../../ui-components/customer-memos/customer-memos';
import CustomerDocuments from '../../../ui-components/customer-documents/customer-documents';
import { useService } from '../../../contexts/service-context';
import { useServiceCustomer } from '../../../contexts/service-customer-context';
import { useUser } from '../../../contexts/user-context';
import { useConfigurations } from '../../../contexts/configurations-context';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';
import CustomerStatusStepper from '../../../ui-components/customer-status-stepper/customer-status-stepper';
import CustomerActivities from '../../../ui-components/customer-activities/customer-activities';
import { useActivities } from '../../../contexts/activities-context';
import { useEthosModal } from '../../../contexts/ethos-modal-context';
import { getServiceInternalName } from "../../../utils/functions/index"
import ActivityModalContent from '../../../ui-components/ethos-modal/activity-modal-content/activity-modal-content';
import { Prompt } from 'react-router-dom';

// const UploadComponentCustomer = ({ defaultValue, isDisable = false, handleUpload, textBtn }) => {
// 	return (
// 		<form onSubmit={handleUpload}>
// 			<select value={defaultValue} className="form-select mb-2" name="typefile" id="typefile">
// 				{
// 					!isDisable ?
// 						<>
// 							{defaultValue === 'docid' ? <option value="docid">Documento D'identità</option> : null}
// 							{defaultValue === 'doccf' ? <option value="doccf">Codice Fiscale</option> : null}
// 							{defaultValue === 'docman' ? <option value="docman">Mandato</option> : null}
// 							{defaultValue === 'docproc' ? <option value="docproc">Procura</option> : null}
// 							{defaultValue === 'docpriv' ? <option value="docpriv">Privacy</option> : null}
// 							{defaultValue === 'doc119' ? <option value="doc119">119</option> : null}
// 						</>
// 						: null}
// 				{defaultValue === 'docDecree' ? <option value="docDecree">Decreto Ingiuntivo</option> : null}
// 			</select>

// 			<input type="file" className="form-control mb-2" name="file" id="file" />
// 			<input type="submit" value={textBtn} className="btn btn-primary" />

// 			<hr />
// 		</form>
// 	)
// }

const EditCustomer = (props) => {
	const { id } = props.match.params;
	const { editCustomer, getSingleCustomer, assignToOwner, assignToSales, assignToBroker, changeCustomerStatus, getCustomerActivities, addRelative, removeRelative } = useCustomer();
	const { saveNewActivity, editActivity, deleteActivity, sendResumeByEmail } = useActivities();
	const { getServices } = useService();
	const { getServiceCustomersByCustomerId, newServiceCustomer, deleteServiceCustomer, updateServiceCustomer, setServiceCustomerState, sendTranchePaymentLink } = useServiceCustomer();
	const { getUsers } = useUser();
	const { showSuccessToast, handleError } = useEthosNotification();
	const { openEthosConfirmModal, openSelectUserModal } = useEthosModal();
	const componentRef = useRef()
	const { configurations } = useConfigurations();
	const [customer, setCustomer] = useState(null);
	// const [showUpload, setShowUpload] = useState(false);
	// const [defaultUploadValue, setDefaultUploadValue] = useState(null);
	const [isDisable, setIsDisable] = useState(false);
	// const [textBtn, setTextBtn] = useState("Carica");
	const [services, setServices] = useState([]);
	const [serviceCustomerList, setServiceCustomerList] = useState([]);
	const [users, setUsers] = useState([]);
	const [activeSection, setActiveSection] = useState('customer-data');
	const [customerActivities, setCustomerActivities] = useState([]);
	const [pendingChange, setPendingChange] = useState(false);

	const getCustomer = useCallback(async (customerId) => {
		setCustomer(null);
		const { data, error } = await getSingleCustomer(customerId);

		if (error) {
			return window.location.href = '/customers';
		}

		setIsDisable(!!data.isDisable);
		setCustomer(data);
	}, [getSingleCustomer]);

	useEffect(() => {
		if (id) {
			getCustomer(id);
		}
	}, [id, getCustomer])

	const customerChanged = () => {
		setPendingChange(true);
	}

	const onSubmit = async (data) => {

		const oldRelative = await getSingleCustomer(id)
		const newRelative = data.relatives

		if (oldRelative.error) {
			handleError(oldRelative.error);
			return;
		}

		for (const relative of newRelative) {
			const existsInOriginal = oldRelative.data.relatives.some(r => r.relativeId === relative.relativeId);
			if (!existsInOriginal) {
				const addRelation = await addRelative(id, relative.relation, relative.relativeId)
				if (addRelation.error) {
					handleError(addRelation.error);
					continue;
				}
			} else {
				const originalID = oldRelative.data.relatives.find(r => r.relativeId === relative.relativeId)?.relativeId;
				const originalRelation = oldRelative.data.relatives.find(r => r.relativeId === relative.relativeId)?.relation;
				if (originalRelation !== relative.relation) {
					const removeRelation = await removeRelative(id, originalRelation, originalID)
					if (removeRelation.error) {
						handleError(removeRelation.error);
						continue;
					}
					const addRelation = await addRelative(id, relative.relation, relative.relativeId)
					if (addRelation.error) {
						handleError(addRelation.error);
						continue;
					}
				}
			}
		}


		for (const originalRelative of oldRelative.data.relatives) {
			const existsInCurrent = newRelative.some(r => r.relativeId === originalRelative.relativeId);
			if (!existsInCurrent) {
				const removeRelation = await removeRelative(id, originalRelative.relation, originalRelative.relativeId)
				if (removeRelation.error) {
					handleError(removeRelation.error);
					continue;
				}
			}
		}

		const updatedRelativeCustomer = await getSingleCustomer(id)
		const dataWithNewRelative = { ...data, relatives: updatedRelativeCustomer.data.relatives }
		const { error } = await editCustomer(id, dataWithNewRelative);
		if (error) {
			handleError(error);
			return;
		}
		setPendingChange(false);
		window.location.reload();
	}

	// const uploadFile = async (e) => {
	// 	e.preventDefault();

	// 	setTextBtn("Caricamento file...");

	// 	const type = e.target[0].value;
	// 	const file = e.target[1].files[0];

	// 	if (file.size >= 4194304) {
	// 		setTextBtn("Carica");
	// 		return alert('Il file supera i 4MB.');
	// 	}

	// 	const formData = new FormData();
	// 	formData.append('file', file);

	// 	const { error } = await uploadFileCustomer(id, type, formData);
	// 	if (error !== null) {
	// 		setTextBtn("Carica");
	// 		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	// 	}

	// 	window.location.reload();
	// }

	// const downloadFile = async (type) => {
	// 	const { data, error } = await downloadFileCustomer(id, type);

	// 	if (error !== null) {
	// 		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	// 	}
	// }

	// const deleteFile = async (type) => {
	// 	const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

	// 	if (!confirmDelete) {
	// 		return;
	// 	}

	// 	const { error } = await deleteFileCustomer(id, type);

	// 	if (error !== null) {
	// 		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	// 	}

	// 	window.location.reload();
	// }

	const loadServices = useCallback(async () => {
		const { data, error } = await getServices();
		if (error) {
			handleError(error);
			return window.location.href = '/customers';
		}
		setServices(data.data);
	}, [getServices, handleError]);

	const loadServiceCustomer = useCallback(async (customerId) => {
		const { data, error } = await getServiceCustomersByCustomerId(customerId);
		if (error) {
			handleError(error);
			return window.location.href = '/customers';
		}

		for (var i = 0; i < data.length; i++) {
			if (!data[i].status) {
				data[i].status = "PROPOSED";
			}
		}

		setServiceCustomerList(data);
	}, [getServiceCustomersByCustomerId, handleError]);

	const loadUsers = useCallback(async () => {
		var { data, error } = await getUsers(null, null, null, 0);
		if (error) {
			handleError(error);
			return window.location.href = '/customers';
		}
		setUsers(data.data);
	}, [getUsers, handleError]);

	const loadCustomerActivities = useCallback(async () => {
		if (!customer) { return; }
		const { data, error } = await getCustomerActivities(customer._id);
		if (error) {
			handleError(error);
			return;
		}
		var newVal = data[0].ActivityData;
		newVal.sort(function (a, b) {
			return new Date(b.activityDate) - new Date(a.activityDate);
		});
		setCustomerActivities(newVal);
	}, [customer, getCustomerActivities, handleError]);

	const addCustomerActivity = async (newActivity) => {
		const { error } = await saveNewActivity(newActivity);
		if (error) {
			handleError(error);
			return;
		}
		showSuccessToast("Attività registrata correttamente");
		loadCustomerActivities();
	}

	const deleteCustomerActivity = async (activityId) => {
		const deleteCallback = async () => {
			const { error } = await deleteActivity(activityId);
			if (error) {
				handleError(error);
				return;
			}
			showSuccessToast("Attività eliminata correttamente");
			loadCustomerActivities();
		}
		openEthosConfirmModal("Sicuro di voler eliminare l'attività?", deleteCallback, null, "Conferma eliminazione");
	}

	const doUpdateCustomerActivity = async (activity) => {
		const { error } = await editActivity(activity);
		if (error) {
			handleError(error);
			return;
		}
		loadCustomerActivities();
		showSuccessToast("Attività aggiornata correttamente");
	}

	const sendResumeMail = useCallback(
		async (id, activity) => {
			const { error } = await editActivity(activity);

			if (error) {
				handleError(error);
				return;
			}

			loadCustomerActivities()
			showSuccessToast("Attività aggiornata correttamente");

			const sendEmailError = await sendResumeByEmail(id);
			if (sendEmailError.error) {
				handleError(sendEmailError.error);
				return;
			}
		},
		[editActivity, handleError, loadCustomerActivities, sendResumeByEmail, showSuccessToast]
	);

	useEffect(() => {
		if (!customer) {
			return
		}
		loadServices();
		loadServiceCustomer(customer._id);
		loadUsers()
		loadCustomerActivities();
	}, [customer, loadServices, loadCustomerActivities, loadServiceCustomer, loadUsers]);

	const addServiceCustomer = useCallback(async (serviceCust) => {
		if (!customer) { return; }
		const { data,error } = await newServiceCustomer(serviceCust);
		if (error) {
			handleError(error);
			return;
		}
 
		const serviceName = getServiceInternalName(services, data)

		if (serviceName.toLowerCase() === "recuperocessione") {
			const { error: errorActiveState } = await setServiceCustomerState(data, "ACTIVE");

			if (error) {
				handleError(errorActiveState);
				return;
			}
			showSuccessToast("Servizio attivato correttamente");
		}


		loadServiceCustomer(customer._id);
		showSuccessToast("Servizio aggiunto correttamente");
	}, [customer, newServiceCustomer, services, loadServiceCustomer, showSuccessToast, handleError, setServiceCustomerState]);

	const doSendTranchePaymentLink = async (scId, link) => {
		const { error } = await sendTranchePaymentLink(scId, link);
		if (error) {
			handleError(error);
			return;
		}
		showSuccessToast("Link per il pagamento della tranche inviato correttamente");
	}

	const doUpdateServiceCustomer = async (serviceCust) => {
		const { error } = await updateServiceCustomer(serviceCust);
		if (error) {
			handleError(error);
			return;
		}
		loadServiceCustomer(customer._id);
		showSuccessToast("Servizio aggiornato correttamente");
	}

	const doSetServiceCustomerState = async (serviceCust, state) => {
		var { error } = await setServiceCustomerState(serviceCust, state);
		if (error) {
			handleError(error);
			return;
		}
	};

	const doActivateServiceCustomer = async (serviceCust) => {
		var { error } = await updateServiceCustomer(serviceCust);
		if (error) {
			handleError(error);
			return;
		}
		await doSetServiceCustomerState(serviceCust, "ACTIVE");
		loadServiceCustomer(customer._id);
		showSuccessToast("Servizio avviato correttamente");
	}

	const delServiceCustomer = useCallback(async (id) => {

		var deleteCallback = async (aId) => {
			const { error } = await deleteServiceCustomer(aId);
			if (error) {
				handleError(error);
				return;
			}
			loadServiceCustomer(customer._id);
			showSuccessToast("Servizio eliminato correttamente");
		}
		openEthosConfirmModal("Sicuro di voler eliminare il servizio?", deleteCallback, id, "Conferma eliminazione");
	}, [deleteServiceCustomer, loadServiceCustomer, handleError, openEthosConfirmModal, showSuccessToast, customer]);

	const setNewState = async (newState, reason) => {
		var data = {};
		if (!!reason) {
			data = {
				reason: reason
			}
		}
		const { error } = await changeCustomerStatus(id, newState, data);
		if (error) {
			handleError(error);
			return;
		}
		showSuccessToast("Stato cliente aggiornato con successo!");
		getCustomer(id);
	}

	const doAssignOwner = async (ownerId) => {
		const { error } = await assignToOwner(customer._id, ownerId);
		if (error) {
			handleError(error);
			return;
		}
		getCustomer(customer._id);
		showSuccessToast("Responsabile aggiornato correttamente");
	}

	const doAssignSales = async (ownerId) => {
		const { error } = await assignToSales(customer._id, ownerId);
		if (error) {
			handleError(error);
			return;
		}
		getCustomer(customer._id);
		showSuccessToast("Sales aggiornato correttamente");
	}

	const doAssignBroker = async (ownerId) => {
		const { error } = await assignToBroker(customer._id, ownerId);
		if (error) {
			handleError(error);
			return;
		}
		getCustomer(customer._id);
		showSuccessToast("Segnalatore aggiornato correttamente");
	}

	const getUserFullName = (id) => {
		var s = users.find(e => e._id === id);
		if (s) {
			return s.first_name + " " + s.last_name;
		}
		else {
			return "Non assegnato"
		}
	}

	const changeOwner = () => {
		openSelectUserModal("Selezionare un nuovo responsabile", users, customer.assignedAt, true, ["ADMIN", "AGENTE", "SALES"], doAssignOwner, "Nessun responsabile", true);
	}

	const changeSales = () => {
		openSelectUserModal("Selezionare un nuovo sales", users, customer.sales, false, ["ADMIN", "SALES"], doAssignSales, "Nessun sales", true);
	}

	const changeBroker = () => {
		openSelectUserModal("Selezionare un nuovo segnalatore", users, customer.broker, false, ["ADMIN", "BROKER"], doAssignBroker, "Nessun segnalatore", true);
	}

	const activityParam = {
		configurations: configurations,
		customer: customer,
		activity: undefined,
		customerId: customer?._id,
		ownerId: null,
		users: users
	};

	return (
		<div className="edit-customer-page px-3 py-3" ref={componentRef}>
			{
				customer ?
					<>
						<div className="row">
							<div className="col-md-9">
								{customer ?
									<div className='mb-3'>
										<CustomerStatusStepper customer={customer} serviceCustomerList={serviceCustomerList} activities={customerActivities} setNewState={setNewState} />
									</div> : null}

								<div className="row mb-3">
									<div className="col-12">
										<div className="navbar justify-content-center  bg-light">
											<b>CLIENTE: <span>{customer.first_name.toUpperCase()} {customer.last_name.toUpperCase()}</span></b>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-12 mb-3">
										<nav className="navbar justify-content-center navbar-expand-lg navbar-light bg-light">
											<div className="btn-group" role="group">
												<button
													className={"btn " + ("customer-data" === activeSection ? "btn-primary" : "btn-outline-secondary")}
													onClick={() => setActiveSection("customer-data")}>
													Dati del cliente
												</button>
												<button
													className={"btn " + ("activities" === activeSection ? "btn-primary" : "btn-outline-secondary")}
													onClick={() => setActiveSection("activities")}>
													Storico attività
												</button>
												<button
													className={"btn " + ("documents" === activeSection ? "btn-primary" : "btn-outline-secondary")}
													onClick={() => setActiveSection("documents")}>
													Documenti cliente
												</button>
												<button
													className={"btn " + ("memo" === activeSection ? "btn-primary" : "btn-outline-secondary")}
													onClick={() => setActiveSection("memo")}>
													Note interne
												</button>
											</div>
										</nav>
									</div>
								</div>
								{activeSection === 'customer-data' &&
									<FormCustomer users={users}
										customerChanged={customerChanged}
										services={services}
										serviceCustomerList={serviceCustomerList}
										updateServiceCustomer={doUpdateServiceCustomer}
										activateServiceCustomer={doActivateServiceCustomer}
										delServiceCustomer={delServiceCustomer}
										addServiceCustomer={addServiceCustomer}
										sendTranchePaymentLink={doSendTranchePaymentLink}
										isDisable={isDisable}
										customer={customer}
										handleSave={onSubmit} />
								}
								{activeSection === 'activities' &&
									<CustomerActivities
										users={users}
										activities={customerActivities}
										customer={customer}
										addActivity={addCustomerActivity}
										doDeleteActivity={deleteCustomerActivity}
										doUpdateActivity={doUpdateCustomerActivity}
										sendResumeAndUpdate={sendResumeMail}
									/>
								}
								{activeSection === 'memo' &&
									<CustomerMemos serviceCustomerList={serviceCustomerList} customer={customer} users={users} services={services}></CustomerMemos>
								}
								{activeSection === 'documents' &&
									<CustomerDocuments
										customerId={id} />
								}

							</div>

							<div className="col-md-3">
								<div className="row">

									<div className="row mb-4 justify-content-center align-items-center">
										<div className="col-12  text-center py-2">
											Responsabile: <span className="text-primary fw-bold">{getUserFullName(customer.assignedAt)}	</span>
										</div>
										<div className="col-12  text-center">
											<button onClick={changeOwner} className="btn btn-sm btn-primary">
												<FontAwesomeIcon className='mr-2' icon={faUserTie} />
												Modifica responsabile
											</button>
										</div>

										<hr className="my-3"></hr>

										<div className="col-12  text-center">
											Sales: <span className="text-primary fw-bold">{getUserFullName(customer.sales)}	</span>
										</div>
										<div className="col-12  text-center">
											<button onClick={changeSales} className="btn btn-sm btn-primary">
												<FontAwesomeIcon className='mr-2' icon={faUserTag} />
												Modifica sales
											</button>
										</div>

										<hr className="my-3"></hr>

										<div className="col-12  text-center">
											Segnalatore: <span className="text-primary fw-bold">{getUserFullName(customer.broker)}	</span>
										</div>
										<div className="col-12  text-center">
											<button onClick={changeBroker} className="btn btn-sm btn-primary">
												<FontAwesomeIcon className='mr-2' icon={faUserTie} />
												Modifica segnalatore
											</button>
										</div>
									</div>


									{!!customer && !!activityParam &&
										<ActivityModalContent
											customParams={activityParam}
											confirm={addCustomerActivity}
											isModal={false} />}
								</div>

							</div>
						</div>
					</>
					: null}
			<Prompt
				when={pendingChange}
				message="Ci sono modifiche in corso, sei sicuro di uscire?"
			/>

		</div>
	)
}

export default EditCustomer;