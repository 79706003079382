import { useEffect, useState } from "react";
import { useConfigurations } from "../../../contexts/configurations-context";
import { Controller, useForm } from "react-hook-form";
import { UploadNewFile } from "../../../components/services-configuration/services-configuration";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import { Files } from "../../../models/files.model";
import moment from "moment";

type Props = {
    customParams: {
        serviceType: string,
        serviceTypeId: string
        files: Files[]
        doUploadFile: (data: UploadNewFile, selectedTypeTemplate: string, serviceTypeId: string) => Promise<boolean>
        doAssociateTemplate: (file: Files, serviceTypeId: string) => Promise<boolean>
        doDownloadFile: (file: Files) => Promise<unknown>
    }
    confirm?: () => void,
    closeFunction?: () => void
}

export enum UploadType {
    NEW = "new",
    FROM_ARCHIVE = "fromArchive"
}

type TabButton = {
    key: UploadType,
    label: string
}

const UploadServiceTemplatesModalContent = ({ closeFunction, customParams: { serviceType, serviceTypeId, files, doUploadFile, doDownloadFile, doAssociateTemplate } }: Props) => {

    const { configurations } = useConfigurations();
    const [typeTemplateConfiguration, setTypeTemplateConfiguration] = useState<{ value: string, label: string }[]>([]);
    const [selectedTypeTemplate, setSelectedTypeTemplate] = useState<string>("");
    const [activeTab, setActiveTab] = useState<string | null>(null);
    const { showErrorToast } = useEthosNotification();
    const [selectedArchivedFile, setSelectedArchivedFile] = useState<Files | null>(null);


    const { formState: { isSubmitting, isValid, }, control, trigger, handleSubmit } = useForm<UploadNewFile>({
        mode: "onBlur",
        defaultValues: {
            file: null,
            filename: null
        }
    });

    const tabButton: TabButton[] = [
        { key: UploadType.NEW, label: "Carica da pc" },
        { key: UploadType.FROM_ARCHIVE, label: "Carica da archivio" }
    ];

    const handleConfirm = () => {
        trigger()

        if (!isValid) {
            showErrorToast("Errore nella compilazione del form");
            return
        }

        handleSubmit(async (data) => {

            try {
                const res = await doUploadFile(data, selectedTypeTemplate, serviceTypeId);

                if (res) {
                    closeFunction && closeFunction();
                }
            } catch {
                showErrorToast("Errore nella compilazione del form");
            }
        })();
    }



    const handleSelectArchivedFile = () => {
        try {

            const res = doAssociateTemplate(selectedArchivedFile, serviceTypeId);

            if (res) {
                closeFunction && closeFunction();
            }

        } catch (e) {
            Log.error(e);
        }
    }

    useEffect(() => {

        if (configurations && configurations.typeTemplate) {
            setTypeTemplateConfiguration(configurations.typeTemplate);
        }

    }, [configurations]);

    return (
        <>
            <div className="modal-body">
                <div className=" justify-content-center row">
                    <span className="fw-bold text-center">Carica o seleziona un template per il servizio: {serviceType}</span>
                    <div className="col-12">
                        <select
                            className="form-select d-inline w-100 my-3"
                            onChange={(e) => {
                                setSelectedTypeTemplate(e.target.value);
                            }}
                        >
                            <option value="">Seleziona un template</option>
                            {
                                typeTemplateConfiguration.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))
                            }
                        </select>

                        {
                            selectedTypeTemplate &&
                            <>
                                <div className="btn-group w-100 mb-3" role="group" aria-label="View predefinite">

                                    {tabButton.map((item) => (
                                        <button
                                            key={item.key}
                                            className={"btn " + (item.key === activeTab ? "btn-primary" : "btn-outline-secondary")}
                                            onClick={() => setActiveTab(item.key)}
                                        >
                                            {item.label}
                                        </button>
                                    ))}
                                </div>
                                {activeTab === UploadType.NEW &&
                                    <>
                                        <Controller
                                            control={control}
                                            id="file"
                                            name="file"
                                            render={({ onChange }) => (
                                                <input
                                                    type="file"
                                                    name="file"
                                                    className="form-control mb-2"
                                                    placeholder="Inserisci il file"
                                                    onChange={(e) => {
                                                        const file = e.target.files?.[0];
                                                        if (file) {
                                                            onChange(file);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            id="filename"
                                            name="filename"
                                            render={({ onChange }) => (
                                                <input type="text" className="form-control mb-2" placeholder="Inserisci il nome del file"
                                                    onChange={(e) => {
                                                        const filename = e.target.value;
                                                        if (filename) {
                                                            onChange(filename);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />


                                    </>
                                }

                                {activeTab === UploadType.FROM_ARCHIVE &&
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Selezionato</th>
                                                <th scope="col">File</th>
                                                <th scope="col">Caricato il</th>
                                                <th scope="col">Scadenza</th>
                                                <th scope="col">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {files.filter(f => f.fileType === selectedTypeTemplate).map((f, index) => {
                                                return (
                                                    <tr
                                                        onClick={() => setSelectedArchivedFile(f)}
                                                        className={selectedArchivedFile?._id === f._id ? "bg-light" : ""}
                                                        style={{ cursor: 'pointer' }}
                                                        key={index}
                                                    >
                                                        <th style={{ textAlign: 'center' }}>
                                                            <button
                                                                type="button"
                                                                onClick={() => setSelectedArchivedFile(f)}
                                                                className="btn p-0 border-0 bg-transparent"
                                                            >
                                                                {
                                                                    selectedArchivedFile?._id === f._id ? (
                                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check fa-w-16 text-success" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: '1em', height: '1em' }}>
                                                                            <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                                                                        </svg>
                                                                    ) : (
                                                                        <span style={{ display: 'inline-block', width: '1em', height: '1em' }} />
                                                                    )
                                                                }
                                                            </button>
                                                        </th>
                                                        <td>{f.filename}</td>
                                                        <td>{moment(f.createdAt).format('DD/MM/YYYY')}</td>
                                                        <td>{f.expirationDate ? moment(f.expirationDate).format('DD/MM/YYYY') : "N.D."}</td>
                                                        <td onClick={(e) => e.stopPropagation()}>
                                                            <button onClick={() => doDownloadFile(f)} type="button" className="btn btn-primary">Scarica</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                }
                            </>
                        }
                    </div>
                </div>
            </div >
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal" disabled={isSubmitting}>Chiudi</button>

                {activeTab === UploadType.NEW && <button onClick={handleConfirm} type="button" className="btn btn-primary" disabled={isSubmitting}>Conferma</button>}

                {activeTab === UploadType.FROM_ARCHIVE && <button onClick={handleSelectArchivedFile} type="button" className="btn btn-primary" disabled={!selectedArchivedFile}>Conferma</button>}

            </div>
        </>
    )
}

export default UploadServiceTemplatesModalContent;