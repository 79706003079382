type ConsoleLogFn = (...data: unknown[]) => void;

enum LogLevel {
    DEBUG = 5,
    LOG = 4,
    INFO = 3,
    WARN = 2,
    ERROR = 1,
    NONE = 0
}

const DEFAULT_LOGGER_STYLE = (typeof navigator !== 'undefined') && /firefox/i.test(navigator.userAgent)
    ? 'margin-left: 12px;'
    : 'margin-left: 20px;';
const LOCAL_STORAGE_LOG_LEVEL_KEY = 'log';
const FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG = '·     ';

const NOOP = (..._args: unknown[]) => void (0);

const LOGGERS = new Map<string, Logger>();

export class Logger {
    static logLevel = LogLevel.WARN;
    static stopwatch = Date.now();

    public silent = false;
    public usePrivateStopwatch = false;

    private _stopwatch = Date.now();
    private _name: string;
    private _traceStyle: string;
    private _infoStyle: string;

    private _log: ConsoleLogFn;
    private _debug: ConsoleLogFn;
    private _info: ConsoleLogFn;
    private _warn: ConsoleLogFn;
    private _error: ConsoleLogFn;

    get log() {
        return this.getLog(LogLevel.LOG);
    }
    get debug() {
        return this.getLog(LogLevel.DEBUG);
    }
    get info() {
        return this.getLog(LogLevel.INFO);
    }
    get warn() {
        return this.getLog(LogLevel.WARN);
    }
    get error() {
        return this.getLog(LogLevel.ERROR);
    }

    constructor(
        public readonly name: string
    ) {
        this._name = formatLoggerName(name);
        this._traceStyle = createLoggerStyle(name);
        this._infoStyle = this._traceStyle + DEFAULT_LOGGER_STYLE;

        this._log = this.buildLogFn(LogLevel.LOG, FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG);
        this._debug = this.buildLogFn(LogLevel.DEBUG, FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG);
        this._info = this.buildLogFn(LogLevel.INFO, FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG);
        this._warn = this.buildLogFn(LogLevel.WARN, FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG);
        this._error = this.buildLogFn(LogLevel.ERROR, FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG);
    }

    /**
     * Imposta il livello di log da debug a error
     * @param value livello di log
     */
    public setLogLevel(value: keyof typeof LogLevel | null | undefined) {
        Logger.logLevel = getLogLevelFromName(value);
    }

    /**
     * Crea un nuovo logger con il nome specificato, qualsiasi log avrà quel prefisso
     * @param name nome del logger
     */
    public getLogger(name: string) {
        let l = LOGGERS.get(name);
        if (!l) {
            l = new Logger(name);
            LOGGERS.set(name, l);
        }

        return l;
    }

    private getAndUpdateGlobalCallTimeDelta() {
        const delta = formatLoggerDeltaTime(Date.now() - Logger.stopwatch, '');
        Logger.stopwatch = Date.now();

        return delta;
    }

    private getAndUpdatePrivateCallTimeDelta() {
        const delta = formatLoggerDeltaTime(Date.now() - this._stopwatch, '*');
        this._stopwatch = Date.now();

        return delta;
    }

    private getLog(level: LogLevel): ConsoleLogFn {
        if (level > Logger.logLevel || this.silent === true) {
            return NOOP;
        }

        const delta = this.usePrivateStopwatch
            ? this.getAndUpdatePrivateCallTimeDelta()
            : this.getAndUpdateGlobalCallTimeDelta();

        if (delta === FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG) {
            if (level === LogLevel.LOG) { return this._log; }
            if (level === LogLevel.DEBUG) { return this._debug; }
            if (level === LogLevel.INFO) { return this._info; }
            if (level === LogLevel.WARN) { return this._warn; }
            if (level === LogLevel.ERROR) { return this._error; }
        }

        return this.buildLogFn(level, delta);
    }

    private buildLogFn(level: LogLevel, delta: string): ConsoleLogFn {
        if (level === LogLevel.LOG) { return console.log.bind(console, `%c${delta} ${this._name}`, this._infoStyle); }
        if (level === LogLevel.DEBUG) { return console.debug.bind(console, `%c${delta} ${this._name}`, this._infoStyle); }
        if (level === LogLevel.INFO) { return console.info.bind(console, `%c${delta} ${this._name}`, this._infoStyle); }
        if (level === LogLevel.WARN) { return console.warn.bind(console, `%c${delta} ${this._name}`, this._traceStyle); }
        if (level === LogLevel.ERROR) { return console.error.bind(console, `%c${delta} ${this._name}`, this._traceStyle); }

        return NOOP;
    }
}

function formatLoggerName(name: string) {
    if (name.length > 20) {
        return name.substring(0, 20);
    }

    return name.padEnd(20);
}

function formatLoggerDeltaTime(delta: number, mark: string): string {
    if (delta < 3) {
        return FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG;
    }

    if (delta < 999) {
        return `${mark}${delta}ms`.padEnd(6);
    }

    if (delta < 60000) {
        return `${mark}${(delta * 0.001).toFixed(2)}s`.padEnd(6);
    }

    if (delta < 3600000) {
        return `${mark}${(delta / 60000).toFixed(2)}m`.padEnd(6);
    }

    return FORMAT_LOGGER_DELTA_TIME_EMPTY_MSG;
}

function createLoggerStyle(name: string) {
    let h = 0;
    let h1 = 0xdeadbeef ^ 0, h2 = 0x41c6ce57 ^ 0;
    for (let i = 0, ch; i < name.length; i++) {
        ch = name.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    h = 4294967296 * (2097151 & h2) + (h1 >>> 0);

    const s = [70, 80, 90, 100][Math.abs(Math.trunc(h / 360)) % 4]
    const l = [30, 50, 70, 90][Math.abs(Math.trunc(h / 360 / 4)) % 4]

    h = Math.abs(h % 359);

    const lp = l * 0.01;
    const a = s * Math.min(lp, 1 - lp) * 0.01;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = lp - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color);
    };

    const r = f(0);
    const g = f(8);
    const b = f(4);

    const brightness = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);

    return `background: hsl(${h},${s}%,${l}%);color: ${(brightness > 125) ? 'black' : 'white'};border-radius: 4px; padding: 0 4px;`;
}

function getLogLevelFromName(value: string | null | undefined) {
    if (!value) {
        return LogLevel.NONE;
    }

    return LogLevel[value.toUpperCase() as 'NONE'] || LogLevel.NONE;
}

Logger.logLevel = getLogLevelFromName(localStorage.getItem(LOCAL_STORAGE_LOG_LEVEL_KEY) || 'WARN');

window.addEventListener('storage', (e) => {
    if (e.key !== LOCAL_STORAGE_LOG_LEVEL_KEY) {
        return;
    }

    Logger.logLevel = getLogLevelFromName(e.newValue);
});

const Log = new Logger('|Log');
type WindowWithLogger = Window & typeof globalThis & { Log: Logger };
(window as WindowWithLogger).Log = Log;

if (process.env.NODE_ENV === 'development') {
    Log.setLogLevel('DEBUG');
    Log.warn('Log level is DEBUG!');
}

declare global {
    const Log: Logger;
}

export default Log;
