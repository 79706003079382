import { useEffect, useState, useCallback } from "react";
import { Service } from "../../models/service.model";
import { useService } from '../../contexts/service-context';
import { useEthosNotification } from "../../contexts/ethos-notification-context";
import "./services-configuration.css";
import { Logger } from "../../logger";
import { useEthosModal } from "../../contexts/ethos-modal-context";
import { useFiles } from "../../contexts/files-context";
import { Files } from "../../models/files.model";
import { useConfigurations } from "../../contexts/configurations-context";

const logger = new Logger("ServicesConfiguration");

export type ModalFile = Pick<Files, "_id" | "filename" | "createdAt" | "expirationDate"> & { status: string, templateType: string, serviceId: string };

export type UploadNewFile = {
	file: FileList | null
	filename: string | null
}

const ServicesConfiguration = () => {
	const [services, setServices] = useState<Service[] | null>(null);
	const [files, setFiles] = useState<Files[] | null>(null);
	const [typeTemplateConfiguration, setTypeTemplateConfiguration] = useState<{ value: string, label: string }[] | null>(null);

	const { getServices, updateService, uploadServiceTemplate, associateServiceTemplate } = useService();
	const { handleError, showErrorToast, showSuccessToast } = useEthosNotification();
	const { openServiceTemplatesModal, openUploadServiceTemplatesModal } = useEthosModal();
	const { getFilesInfo, downloadFile } = useFiles();
	const { configurations } = useConfigurations();

	const loadServices = useCallback(async () => {
		try {
			const { data, error } = await getServices();

			if (error) {
				handleError(error);
				return;
			}

			logger.log("SERVICES", data.data);

			setServices(data.data);
		} catch (err) {
			handleError(err);
		}
	}, [getServices, handleError]);

	const loadFiles = useCallback(async () => {
		try {
			const { data, error } = await getFilesInfo("TEMPLATE");

			if (error) {
				handleError(error);
				return;
			}

			logger.log("FILES", data.data);

			setFiles(data.data);
		} catch (error) {
			handleError(error);
		}
	}, [getFilesInfo, handleError]);


	const doDownloadFile = async (file: ModalFile) => {
		const { error } = await downloadFile(file._id);
		if (error) {
			showErrorToast("Errore durante il download del file!");
			return;
		}
	}

	const doLinkFileAndService = async (file: ModalFile, serviceId: string) => {
		const { error } = await associateServiceTemplate(serviceId, file._id);
		if (error) {
			handleError(error);
			return;
		}

		await loadServices();
		await loadFiles();

		return true
	}

	const doArchiveFile = async (file: ModalFile): Promise<unknown> => {

		const svr = { ...services.find((service) => service._id === file.serviceId) }

		for (const template of svr.templates) {
			if (template.id === file._id) {
				template.status = "ARCHIVED";
				break;
			}
		}

		var { data, error } = await updateService(svr);
		if (error) {
			handleError(error);
			return;
		}

		await loadServices();
		await loadFiles();

		showSuccessToast("Servizio aggiornato correttamente!");

		return data
	}

	const doUploadFile = async ({ file, filename }: UploadNewFile, selectedTypeTemplate: string, serviceTypeId: string): Promise<boolean> => {
		const { error } = await uploadServiceTemplate(serviceTypeId, selectedTypeTemplate, file, filename);

		if (error) {
			handleError(error);
			return false;
		}

		await loadServices();
		await loadFiles();

		return true
	}

	const openServiceModal = (service: Service) => {

		const modalFile: ModalFile[] = []

		for (const template of service.templates) {
			const file = files.find((file) => file._id === template.id);
			if (file) {
				modalFile.push({
					_id: file._id,
					filename: file.filename,
					createdAt: file.createdAt,
					templateType: typeTemplateConfiguration?.find(tt => tt.value === file.fileType)?.label || "N.D.",
					expirationDate: file.expirationDate,
					status: template.status,
					serviceId: service._id
				})
			}
		}

		openServiceTemplatesModal("customTemplateModal", modalFile, doDownloadFile, doArchiveFile);
	}

	const openUpdateServiceModal = (service: Service) => {
		openUploadServiceTemplatesModal("customTemplateModal", service.displayName, service._id, files, doUploadFile, doDownloadFile, doLinkFileAndService);
	}

	useEffect(() => {

		if (configurations && configurations.typeTemplate) {
			setTypeTemplateConfiguration(configurations.typeTemplate);
		}

		loadServices();
		loadFiles()
	}, [configurations, loadFiles, loadServices]);


	if (services === null) {
		return <h3 className="text-center">Caricamento in corso...</h3>;
	}

	return (
		<>
			<h3 className="text-center">Configurazione dei servizi</h3>
			<h5 className="text-center pb-3">Numero di servizi: {services.length}</h5>
			<div className="serviceConfiguration__root">
				{services.map((service, index) => (
					<div className="serviceConfiguration__card" key={service._id}>
						<div className="serviceConfiguration__card--header">
							<h5>{service.displayName}</h5>
							{service.templates.length > 0 ? (
								<div className="serviceConfiguration__card--title">
									<span>N° template: <strong>{service.templates.length}</strong></span>
									<div className="serviceConfiguration__card--templates">
										<span>Attivi: {service.templates.filter((t) => t.status === "ACTIVE").length}</span>
										{service.templates.some((t) => t.status === "ARCHIVED") &&
											<span>Archiviati: {service.templates.filter((t) => t.status === "ARCHIVED").length}
											</span>}
									</div>
								</div>
							) : (
								"Nessun template caricato"
							)}
						</div>
						<div className="serviceConfiguration__card--body">
							<div className="btn-group" role="group">
								<button
									id="btnGroup1"
									type="button"
									className="btn btn-primary"
									disabled={service.templates.length === 0}
									onClick={() => {
										openServiceModal(service);
									}}
								>
									Visualizza
								</button>
								<button
									id="btnGroup2"
									type="button"
									className="btn btn-outline-secondary"
									onClick={() => {
										openUpdateServiceModal(service);
									}}
								>
									Carica
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default ServicesConfiguration;

