import moment from "moment";
import { ModalFile } from "../../../components/services-configuration/services-configuration"
import "./service-templates-modal.css"
import { useEthosModal } from "../../../contexts/ethos-modal-context";
import { useState } from "react";

type Props = {
	customParams: {
		files: ModalFile[],
		doDownloadFile: (file: ModalFile) => void,
		doArchiveTemplate: (file: ModalFile) => Promise<unknown>
	}
	confirm?: () => void,
	closeFunction?: () => void
}

const ServiceTemplatesModalContent = ({ closeFunction, customParams: { files, doArchiveTemplate, doDownloadFile } }: Props) => {

	const [innerFiles, setInnerFiles] = useState<ModalFile[]>(files);

	const { openEthosConfirmModal } = useEthosModal();

	const confirmArchive = (file: ModalFile) => {
		openEthosConfirmModal("Sicuro di voler archiviare il template?", async () => {
			const data = await doArchiveTemplate(file)
			if (data !== undefined) {
				setInnerFiles(prevFiles =>
					prevFiles.map(f =>
						f._id === file._id ? { ...f, status: "ARCHIVED" } : f
					)
				);
			}
		}, file._id, "Conferma archiviazione");
	}

	return (
		<>
			<div className="modal-body">
				<div className=" justify-content-center row">
					<div className="col-12">
						{files && files.length > 0 ?
							<table className="table table-striped" style={{ tableLayout: "fixed", wordWrap: "break-word" }}>
								<thead>
									<tr>
										<th>Nome File</th>
										<th>Caricato il</th>
										<th>Scadenza</th>
										<th>Tipologia Template</th>
										<th>Stato</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										innerFiles.sort((a, b) => {
											if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
												return -1
											};
											if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
												return 1;
											}

											return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
										})
											.map((file, index) => {
												return (
													<tr key={index}>
														<td>{file.filename}</td>
														<td>{moment(file.createdAt).format('DD/MM/YYYY')}</td>
														<td>{file.expirationDate ? moment(file.expirationDate).format('DD/MM/YYYY') : "N.D"}</td>
														<td>{file.templateType}</td>
														<td>{file.status === "ACTIVE" ? "Attivo" : "Archiviato"}</td>
														<td>
															<div className="actionArea" role="group">
																<button onClick={() => doDownloadFile(file)} type="button" className="btn btn-primary">Scarica</button>
																{file.status === "ACTIVE" && <button onClick={() => {
																	confirmArchive(file)
																}} type="button" className="btn btn-danger">Archivia</button>}

															</div>
														</td>
													</tr>
												)
											})
									}
								</tbody>
							</table> :
							<span>Non è stato caricato nessun file</span>
						}
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
			</div>
		</>
	)
}

export default ServiceTemplatesModalContent;