import { useCallback, useEffect, useRef, useState } from "react";
import { useConfigurations } from "../../../contexts/configurations-context";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import { useServiceCustomer } from "../../../contexts/service-customer-context";
import { getProgressStatusLabelFromConfiguration, isExpired, renderPaginator } from "../../../utils/functions";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExclamationTriangle, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useBank } from "../../../contexts/banks-context";
import { useUser } from "../../../contexts/user-context";

const ListPracticesCqs = () => {
  const LIMIT = 12;
  const [offset, setOffset] = useState<number>(0);

  const [role, setUserRole] = useState<string | null>(null);

  const { configurations } = useConfigurations();
  const { getServiceCustomers, getServiceCustomersCsv } = useServiceCustomer();
  const { handleError } = useEthosNotification();
  const { getBanks } = useBank();
  const { getUsers } = useUser();

  const [paginationElement, setPaginationElement] = useState<JSX.Element | null>(null);

  const [cqsDataRequest, setCqsDataRequest] = useState([]);

  const [search, setSearch] = useState<string | null>(null);

  const [cqsStatus, setCqsStatus] = useState<string | null>(null);
  const [cqsBank, setCqsBank] = useState<string | null>(null);
  const [userAgent, setUserAgent] = useState<string | null>(null);

  const [selectCqsStatus, setSelectCqsStatus] = useState<{ value: string; label: string }[] | null>(null);
  const [selectBank, setSelectBank] = useState<{ value: string; label: string }[] | null>(null);
  const [selectUserAgent, setSelectUserAgent] = useState<{ value: string; label: string }[] | null>(null);

  const cqsStatusRef = useRef<HTMLSelectElement>();
  const cqsBankRef = useRef<HTMLSelectElement>();
  const userAgentRef = useRef<HTMLSelectElement>();
  const searchRef = useRef<HTMLInputElement>();

  const [sortColumn, setSortColumn] = useState<{ column: string | null; direction: number }>({ column: null, direction: 0 });
  const [sortFilter, setSortFilter] = useState<string | null>(null);

  async function getUserRole() {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      const payload = accessToken.payload;

      const role = payload["cognito:groups"] && payload["cognito:groups"].length ? payload["cognito:groups"][0] : "CLIENTE";

      setUserRole(role);
    } catch (error) {
      console.error("Error fetching user session:", error);
      setUserRole("CLIENTE");
    }
  }
  const initCqs = useCallback(async () => {
    const { data, error } = await getServiceCustomers("RecuperoCessione", LIMIT, offset, sortFilter, search, null, cqsStatus, cqsBank, userAgent);

    if (error) {
      handleError(error);
      return;
    }

    setCqsDataRequest(data.data);
    renderPaginator(data.length, LIMIT, setOffset, setPaginationElement, offset);
  }, [cqsBank, cqsStatus, getServiceCustomers, handleError, offset, search, sortFilter, userAgent]);

  const initBanks = useCallback(async () => {
    const { data, error } = await getBanks(null, null, null, null);
    if (error) {
      handleError(error);
      return;
    }

    const response = data.data.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    setSelectBank(response);
  }, [getBanks, handleError]);

  const initUsers = useCallback(async () => {
    const { data, error } = await getUsers(null, null, null, null);
    if (error) {
      handleError(error);
      return;
    }

    const response = data.data.map((item) => {
      return {
        value: item._id,
        label: item.username,
      };
    });
    setSelectUserAgent(response);
  }, [getUsers, handleError]);

  const init = useCallback(() => {
    initCqs();
  }, [initCqs]);

  const resetFilter = () => {
    setSortFilter(null);
    setOffset(0);
    cqsStatusRef.current.value = null;
    setCqsStatus(null);
    cqsBankRef.current.value = null;
    setCqsBank(null);
    userAgentRef.current.value = null;
    setUserAgent(null);
    searchRef.current.value = null;
    setSearch(null);
  };

  const getRowColorStatus = (status: string): string => {
    switch (status) {
      case "KO":
        return "red";
      case "RECUPERATA":
      case "COMPLETATO":
        return "green";
      case "IN_CORSO":
        return "orange";
      default:
        return "";
    }
  };

  const onSearchChange = debounce((value: string) => {
    setSearch(value);
  }, 500);

  const onChangeStatus = debounce((value: string) => {
    setCqsStatus(value);
  }, 500);

  const onChangeBank = debounce((value: string) => {
    setCqsBank(value);
  }, 500);

  const sortingColumn = (columnName: string) => {
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        setSortColumn({
          column: columnName,
          direction: sortColumn.direction === 1 ? -1 : 1,
        });
      } else {
        setSortColumn({ column: columnName, direction: 1 });
      }
    }
  };

  const renderSortArrow = (columnName: string) => {
    if (sortFilter === null) {
      return <FontAwesomeIcon icon={faSort} style={{ opacity: ".50" }} className="ml-2" />;
    }
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        return sortColumn.direction === 1 ? (
          <FontAwesomeIcon icon={faSortUp} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faSortDown} className="ml-2" />
        );
      }
    }
  };

  const getCustomerFullname = (item): string => {
    const customer = item.customerData.find((c) => c._id === item.customerId);
    if (!!customer) {
      return `${customer.first_name} ${customer.last_name}`;
    }
    return "N.D.";
  };

  const getOwnerName = (item) => {
    const customer = item.customerData.find((c) => c._id === item.customerId);
    if (!customer) return "N.D.";
    const assignedAt = customer.assignedData.find((a) => a._id === customer.assignedAt);
    if (!assignedAt) return "N.D.";
    return `${assignedAt.first_name} ${assignedAt.last_name}`;
  };


  const handleGenerateCSV = async () => {
    const { data, error } = await getServiceCustomersCsv("RecuperoCessione", LIMIT, offset, sortFilter, search, null, cqsStatus, cqsBank, userAgent);
    if (error) {
      handleError(error);
      return;
    }

    const buffer = new Uint8Array(data.buffer.Body.data);
    const filename = data.filename;

    const blob = new Blob([buffer], {});

    const url = window.URL.createObjectURL(blob);
    const element = document.createElement("a");

    element.href = url;
    element.download = filename;

    document.body.appendChild(element);

    element.click();
    element.remove();
  };


  const isLoaded = () => {
    return configurations && role;
  };

  useEffect(() => {
    init();
  }, [offset, init]);

  useEffect(() => {
    if (!!sortColumn.column) {
      setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
    }
  }, [sortColumn]);

  useEffect(() => {
    getUserRole();
    initBanks();
    initUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configurations) {
      const list = (configurations.typeServiceStatus.recuperoCessione as { value: string; label: string }[]).map((item) => {
        return {
          ...item,
        };
      });
      setSelectCqsStatus(list);
    }
  }, [configurations]);

  return (
    <div className="list-practices-page">
      {isLoaded() && (
        <div className="row">
          <div className="col-12 text-center">
            <h3>Lista dei servizi di tipo Recupero Cessione del V</h3>
          </div>
          <div className="bg-light">
            <div className="d-flex justify-content-end align-items-center py-3">
              <div className="flex-1">
                <button className="btn btn-primary" onClick={() => {
                  handleGenerateCSV()
                }}>Esporta in CSV</button>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-12 col-md-12 col-lg-2 my-2">
                <input
                  ref={searchRef}
                  className="form-control"
                  placeholder="Cerca un cliente"
                  onChange={(e) => {
                    onSearchChange(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-2 my-2">
                <select
                  title="bank select"
                  ref={cqsBankRef}
                  className="form-select"
                  placeholder="Cerca per banca"
                  onChange={(e) => {
                    onChangeBank(e.target.value);
                  }}
                >
                  <option value="null">Filtra per banca</option>
                  {selectBank !== null &&
                    selectBank.map((bank, index) => {
                      return (
                        <option key={`${bank.value}_${index}`} value={bank.value}>
                          {bank.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-12 col-md-12 col-lg-3 my-2">
                <select
                  title="status select"
                  ref={cqsStatusRef}
                  className="form-select"
                  placeholder="cerca per stato"
                  onChange={(e) => {
                    onChangeStatus(e.target.value);
                  }}
                >
                  <option value="null">Filtra per stato avanzamento</option>
                  {selectCqsStatus !== null &&
                    selectCqsStatus.map((status, index) => {
                      return (
                        <option key={`${status.value}_${index}`} value={status.value}>
                          {status.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              {role === "ADMIN" ? (
                <>
                  <div className="col-12 col-md-12 col-lg-3 my-2">
                    <select
                      title="user select"
                      ref={userAgentRef}
                      className="form-select"
                      placeholder="Cerca un utente"
                      onChange={(e) => {
                        setUserAgent(e.target.value);
                      }}
                    >
                      <option value="null">Filtra per utente</option>
                      {selectUserAgent !== null &&
                        selectUserAgent.map((agent, index) => {
                          return (
                            <option key={`${agent.value}_${index}`} value={agent.value}>
                              {agent.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </>
              ) : null}
              <div className="col-12 col-md-12 col-lg-2 my-2">
                <div className="btn-group w-100">
                  <button className="btn btn-primary">Cerca</button>

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      resetFilter();
                    }}
                  >
                    Resetta Filtri
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-3" />
          {/* PAGINATOR */}
          <div className="col-12 mt-1">
            {cqsDataRequest && cqsDataRequest.length > 0 ? (
              <nav className="pagination-section">
                <ul className="pagination justify-content-center">{paginationElement}</ul>
              </nav>
            ) : null}
          </div>

          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th style={{ width: "5%" }}></th>
                <th></th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("nContract");
                  }}
                >
                  N° Contratto {renderSortArrow("nContract")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("bank");
                  }}
                >
                  Banca {renderSortArrow("bank")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("customerData.first_name");
                  }}
                >
                  Cliente {renderSortArrow("customerData.first_name")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("customerData.assignedData.first_name");
                  }}
                >
                  Operatore {renderSortArrow("customerData.assignedData.first_name")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("serviceStatus.status");
                  }}
                >
                  Stato Avanzamento {renderSortArrow("serviceStatus.status")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("serviceStatus.expireAt");
                  }}
                >
                  Data Scadenza {renderSortArrow("serviceStatus.expireAt")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("createdAt");
                  }}
                >
                  Data Creazione {renderSortArrow("createdAt")}
                </th>
              </tr>
            </thead>
            <tbody className="cqs-list">
              {cqsDataRequest != null && (
                cqsDataRequest.map((cqs, index) => {
                  return (
                    <tr key={`${cqs._id}_${index}`} className={getRowColorStatus(cqs.serviceStatus !== null ? cqs.serviceStatus.status : "N.D.")}>
                      <td>
                        <button title="edit" className="btn btn-secondary" disabled={cqs.status !== "ACTIVE" || cqs.serviceStatus == null}>
                          <NavLink to={`service-details/${cqs._id}`} className="text-white">
                            <FontAwesomeIcon icon={faEdit} />
                          </NavLink>
                        </button>
                      </td>
                      <td>
                        {cqs.serviceStatus === null || cqs.serviceStatus.expireAt === null ? (
                          ""
                        ) : moment(cqs.serviceStatus.expireAt) > moment() ? null : (
                          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#f00" }} />
                        )}
                      </td>
                      <td className="text-uppercase">{cqs?.nContract || "N.D."}</td>
                      <td className="text-uppercase">{cqs?.bank || "N.D."}</td>
                      <td className="text-uppercase">
                        <a href={"/customers/" + cqs.customerId}>
                          <span style={{ color: "black" }}>{getCustomerFullname(cqs)}</span>
                        </a>
                      </td>
                      <td>{getOwnerName(cqs)}</td>
                      <td className="text-uppercase">
                        {cqs.serviceStatus !== null
                          ? getProgressStatusLabelFromConfiguration(cqs.serviceStatus.status, configurations, "default")
                          : "N.D."}
                      </td>
                      <td
                        className={isExpired(
                          cqs.serviceStatus === null || cqs.serviceStatus.expireAt === null
                            ? "N.D."
                            : moment(cqs.serviceStatus.expireAt).format("DD/MM/YYYY")
                        )}
                      >
                        {cqs.serviceStatus === null || cqs.serviceStatus.expireAt === null
                          ? "N.D."
                          : moment(cqs.serviceStatus.expireAt).format("DD/MM/YYYY")}
                      </td>
                      <td>{moment(cqs.createdAt).format("DD/MM/YYYY")}</td>
                    </tr>
                  );
                })
              )}

              {cqsDataRequest.length === 0 && (
                <tr>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    Non è stato trovato nessun servizio di cancellazione
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ListPracticesCqs;
