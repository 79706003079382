import { useEffect, useState } from 'react';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../ui-components/custom-components';
import { InputNumber } from '../../../../../ui-components/forms';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export enum CalcsType {
	INTERESTS = "INTERESTS",
	INSTALLMENTS = "INSTALLMENTS"
};

type FilterItem = {
	key: CalcsType;
	label: string;
};

type Expenses = {
	order: number,
	label: string,
	amount: number
	edit: number
}

export type CalcsData = {
	haveCalcs: boolean,
	calcsType: string | null
	nRate: number | null,
	nRateMax: number | null,
	percDur: number | null,
	percSpes: number | null,
	installmentMonthly: number | null,
	installmentMonthlyLabel: string,
	expenses: Expenses[] | null
	editAss: number | null,
	editCommBanc: number | null,
	editCommInt: number | null,
	editSpesa: number | null,
	rncImport: number | null,
	rncImportLett: string,
	importLett: string,
}

interface CalsModal {
	data: CalcsData
	newData: (newData: CalcsData) => void
	closeModal: (value: boolean) => void
}

const CalcsModal = ({ data, newData, closeModal }: CalsModal) => {
	const [nRate, setNRate] = useState<number>(data?.nRate ?? 0);
	const [nRateMax, setNRateMax] = useState<number>(data?.nRateMax ?? 0);
	const [percDur, setPercDur] = useState<number>(data?.percDur ?? 0);
	const [percSpes, setPercSpes] = useState<number>(data?.percSpes ?? 0);

	const [installmentMonthly, setInstallmentMonthly] = useState<number>(data?.installmentMonthly ?? 0);
	const [installmentMonthlyLabel, setInstallmentMonthlyLabel] = useState<string>(data.installmentMonthlyLabel ?? "")

	const [editingInstallment, setEditingInstallment] = useState(false);
	const [editingIndex, setEditingIndex] = useState<number | null>(null);


	const { control, getValues } = useForm(
		{
			mode: "onBlur",
			defaultValues: {
				expenses: data?.expenses
			}
		}
	)

	const { fields, append, remove } = useFieldArray({
		control,
		name: "expenses"
	})

	const [selectedCalcsType, setSelectedCalcsType] = useState<string>(data.calcsType ?? CalcsType.INSTALLMENTS);

	const customFilters: FilterItem[] = [
		{ key: CalcsType.INSTALLMENTS, label: "Rate" },
		{ key: CalcsType.INTERESTS, label: "Interessi" }
	];

	const handleSaveData = () => {

		const { expenses } = getValues()

		console.log(expenses)

		newData({
			nRate,
			nRateMax,
			percDur,
			percSpes,
			installmentMonthly,
			expenses,
			installmentMonthlyLabel,
			haveCalcs: true,
			editAss: data.editAss,
			editCommBanc: data.editCommBanc,
			editCommInt: data.editCommInt,
			editSpesa: data.editSpesa,
			rncImport: data.rncImport,
			rncImportLett: data.rncImportLett,
			importLett: data.importLett,
			calcsType: selectedCalcsType,
		})
		closeModal(false)
	}


	useEffect(() => {
		const nRateMaxFix = nRateMax || 1;
		setPercDur(nRate / nRateMaxFix);
		setPercSpes(1 - (nRate / nRateMaxFix));
	}, [nRateMax, nRate])

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Genera Calcoli</h3>
					<button className="close-button" onClick={() => closeModal(false)}>&times;</button>
				</div>
				<hr />

				<div className="d-flex gap-2 my-3 align-items-center">
					<span className='navbar-brand'>Tipologia di calcolo</span>
					<div className="btn-group" role="group" aria-label="View predefinite">
						<div className="btn-group" role="group" aria-label="View predefinite">
							{customFilters.map((item) => (
								<button
									key={item.key}
									className={"btn " + (item.key === selectedCalcsType ? "btn-primary" : "btn-outline-secondary")}
									onClick={() => setSelectedCalcsType(item.key)}
								>
									{item.label}
								</button>
							))}
						</div>
					</div>

				</div>

				{/* First step */}
				<div className="step">
					<p className="fw-bold">Numero {selectedCalcsType === CalcsType.INSTALLMENTS ? "rate" : "interessi contrattualizzati"} da pagare:</p>
					<div className="mb-3">
						<div className="row align-items-center gap-2">
							<div className={`col-12 col-md-6 col-lg-${selectedCalcsType === CalcsType.INSTALLMENTS ? '2' : '3'}`}>
								<InputNumber defaultValue={nRate} type="number" price={selectedCalcsType === CalcsType.INTERESTS ? true : false} name="n-rate" step="1" onChange={(e: string) => { setNRate(parseFloat(e)) }} label={undefined} />
							</div>
							<div style={{ display: "contents" }}>
								SU
							</div>
							<div className={`col-12 col-md-6 col-lg-${selectedCalcsType === CalcsType.INSTALLMENTS ? '2' : '3'}`}>
								<InputNumber defaultValue={nRateMax} type="number" price={selectedCalcsType === CalcsType.INTERESTS ? true : false} name="n-rate-max" step="1" onChange={(e: string) => { setNRateMax(parseFloat(e)) }} label={undefined} />
							</div>
						</div>
					</div>

					<p><strong>Percentuale Durata Contratto:</strong> {`${percDur.toFixed(1)} / ${(percDur * 100).toFixed(0)}%`}</p>
					<p><strong>Percentuale Spese da Stornare:</strong> {`${percSpes.toFixed(1)} / ${(percSpes * 100).toFixed(0)}%`}</p>
				</div>

				<hr />

				{/* Secondo step */}
				<div className="step">
					<div className="d-flex justify-content-end my-3">
						<button className='btn btn-primary' onClick={() => {
							append({
								label: '',
								amount: null,
								edit: null,
								order: fields.length > 0 ? Math.max(...fields.map(f => f.order ?? 0)) + 1 : 0
							});
						}}><FontAwesomeIcon icon={faPlus} /></button>
					</div>

					<div className="row align-items-end">
						<div className="col-12 col-md-6 mt-3">
							<div className="d-flex align-items-center justify-content-between mb-2">
								<div className="flex-grow-1 me-2">
									{editingInstallment ? (
										<input
											type="text"
											className="form-control"
											value={installmentMonthlyLabel}
											onChange={(e) => setInstallmentMonthlyLabel(e.target.value)}
											autoFocus
										/>
									) : (
										<span>
											{installmentMonthlyLabel || '—'}
										</span>
									)}
								</div>

								<div className="d-flex flex-shrink-0 gap-1">
									<button
										type="button"
										className="btn btn-sm btn-outline-secondary"
										onClick={() => setEditingInstallment(!editingInstallment)}
									>
										{editingInstallment ? 'Fine' : 'Modifica'}
									</button>
								</div>
							</div>

							<div className="input-group">
								<span className="input-group-text">&euro;</span>
								<input
									className="form-control"
									type="number"
									step="0.1"
									min={0}
									value={installmentMonthly ?? ''}
									onChange={(e) => setInstallmentMonthly(parseFloat(e.target.value))}
									placeholder="Importo"
								/>
							</div>
						</div>


						{fields && fields
							.sort((a, b) => a.order - b.order)
							.map((field, index) => (
								<div key={field.id} className="col-12 col-md-6 mt-3">
									<Controller
										name={`expenses.${index}.order`}
										control={control}
										defaultValue={field.order}
										render={({ value }) => (
											<input type="hidden" value={value} />
										)}
									/>
									<Controller
										name={`expenses.${index}.edit`}
										control={control}
										defaultValue={field.edit}
										render={({ value }) => (
											<input type="hidden" value={value} />
										)}
									/>
									<div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
										<div className="flex-grow-1 me-2">
											<Controller
												name={`expenses.${index}.label`}
												control={control}
												defaultValue={field.label}
												render={({ onChange, value }) =>
													editingIndex === index ? (
														<input
															type="text"
															value={value ?? ''}
															onChange={onChange}
															className="form-control"
															autoFocus
														/>
													) : (
														<span>
															{value || '—'}
														</span>
													)
												}
											/>
										</div>

										<div className="d-flex flex-shrink-0 gap-1">
											<button
												type="button"
												className="btn btn-sm btn-outline-secondary"
												onClick={() =>
													setEditingIndex(editingIndex === index ? null : index)
												}
											>
												{editingIndex === index ? 'Fine' : 'Modifica'}
											</button>
											<button
												type="button"
												className="btn btn-sm btn-danger"
												onClick={() => remove(index)}
												disabled={editingIndex === index}
											>
												Rimuovi
											</button>
										</div>
									</div>

									<Controller
										name={`expenses.${index}.amount`}
										control={control}
										defaultValue={field.amount}
										render={({ onChange, value }) => (
											<div className='input-group'>
												<span className="input-group-text">&euro;</span>
												<input
													className="form-control"
													type="number"
													step="0.1"
													value={value ?? ''}
													onChange={onChange}
													placeholder="Importo"
												/>
											</div>
										)}
									/>
								</div>
							))}
					</div>

				</div>
				<hr />

				<button type="button" disabled={editingInstallment || editingIndex !== null} className="btn btn-primary" onClick={() => {
					handleSaveData()
				}}>Conferma</button>

			</NewPageWrapperCopy>
		</NewPageWrapper >
	)
}

export default CalcsModal;